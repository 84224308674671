export default class EditMaritalStatusRequest {

    public customerId!: number;

    public maritalStatus: string | null = null;

    public spouseId: number | null = null;

    public spouseFirstName: string | null = null;

    public spouseLastName: string | null = null;

    public spouseSsn = "";

    public spousePhone: string | null = null;

    public spouseEmail: string | null = null;

    constructor(customerId: number, maritalStatus: string | null) {
        this.customerId = customerId;
        this.maritalStatus = maritalStatus;
    }

}
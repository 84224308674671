








































































































import {Component, Vue} from "vue-property-decorator";
import {MARITAL_STATUSES} from "@/constants/ProfileConstants";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import {namespace} from "vuex-class";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import RouteNames from "@/router/RouteNames";
import UserDTO from "@/dto/UserDTO";
import EditMaritalStatusRequest from "@/dto/person/EditMaritalStatusRequest";
import RegexUtils from "@/utils/RegexUtils";
import PersonSearchModal from "@/components/common/PersonSearchModal.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");


@Component({
  components: {
    PhoneInput
  }
})
export default class MaritalStatus extends Vue {

  private maritalStatuses = MARITAL_STATUSES

  private message = "";

  private successful = false;

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private person = new PersonDTO();

  @Auth.State("user")
  private currentUser!: UserDTO;

  @Auth.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  private createNew = false;

  private request = new EditMaritalStatusRequest(this.idParam, null);

  mounted() {
      this.loadPerson();
  }

  get idParam() {
    return Number.parseInt(this.$route.params.id, 10);
  }



  loadPerson() {
    this.startLoading();
    this.message = "";
    return PersonService.getById(Workspaces.getCurrent.id as number).then(
        response => {
          this.person = response.data;
          this.successful = true;
          this.request = new EditMaritalStatusRequest(this.person.id as number, this.person.maritalStatus);
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  get maritalStatusWithSpouse(): boolean {
    return this.request.maritalStatus == 'MARRIED' || this.request.maritalStatus == 'SEPARATED';
  }

  maskSpouseSsn(event: Event) {
    let mask = "___-__-____";
    let oldVal = this.request.spouseSsn?.replace(/\D/g, "");

    if (mask.length >= this.request.spouseSsn.length) {
      this.request.spouseSsn = this.buildSsnWithMask(mask, oldVal);
    } else if (mask.length < this.request.spouseSsn.length) {
      this.request.spouseSsn = this.request.spouseSsn.substr(0, mask.length)
    }
  }

  buildSsnWithMask(mask: string, oldVal: string) {
    let i = 0;

    let newValue = this.deleteSuffixIfNeed(mask.replace(/[_\d]/g, function (a: string) {
      return i < oldVal.length ? oldVal.charAt(i++) : a
    }));

    if (newValue.length <= mask.length) {
      oldVal = newValue;
    }

    if (this.request.spouseSsn.substr(oldVal.length - 1) == "-") {
      newValue = oldVal.substr(0, oldVal.length);
    }

    return newValue;
  }

  deleteSuffixIfNeed(newValue: string) {
    if (newValue.indexOf("_") != -1) {
      newValue = newValue.substr(0, newValue.indexOf("_"))
    }

    if (newValue.substr(newValue.length - 1) == "-") {
      newValue = newValue.substr(0, newValue.length - 1);
    }

    let suffix = newValue.substr(newValue.length - 2);

    if (suffix == " (" || suffix == ") ") {
      newValue = newValue.substr(0, newValue.length - 2)
    }

    return newValue;
  }

  handleInput(event: KeyboardEvent) {
    if (event.key != '\'' && event.key != "-" && !RegexUtils.testLatin(event.key) && event.key != ' ') {
      event.preventDefault();
    }
  }

  cancelCreation() {
    this.createNew = false;
    this.request.spouseFirstName = null;
    this.request.spouseLastName = null;
    this.request.spouseSsn = "";
    this.request.spousePhone = null;
    this.request.spouseEmail = null;
  }

  selectSpouse() {
    this.cancelCreation();
    this.$modal.show(
        PersonSearchModal,
        {
          onPersonSelected: (person: PersonDTO) => {
            this.request.spouseId = person.id as number;
            this.save();
          }
        },
        {width: "50%", height: "auto", scrollable: true}
    );
  }

  save() {
    this.$validator.validateAll().then(
        isValid => {
          if (isValid) {
            this.startLoading();
            this.message = "";
            this.fillOldSpouseDataIfNeed();
            return PersonService.editMaritalStatus(this.request).then(
                response => {
                  this.cancelCreation();
                  this.stopLoading();
                  this.loadPerson();
                },
                error => {
                  this.successful = false;
                  this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                  this.stopLoading();
                }
            )
          }
        }
    )
  }

  fillOldSpouseDataIfNeed() {
    if (this.maritalStatusWithSpouse && !this.createNew && !this.request.spouseId) {
      this.request.spouseId = this.person.spouse?.id as number;
    }
  }

}
